<template>
    <div>
        <div class="tab">
            <el-tabs v-model="index_code" @tab-click="tabClickHandle" style="float:left;margin-top:10px;width:98.4%;position:relative;">
                <el-tab-pane name="DJI">
                    <span slot="label" style="margin-left:16px">{{dji}}
                        <el-tooltip  placement="bottom">
                            <div slot="content">道琼斯工业平均指数由30只有代表性的大工商业公司的股票组成，大致可以反映美国整个工商业股票的价格水平。<br/>由于它是价格加权平均法计算的指数，高价股比低价股在平均指数中更有影响力。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="dji" ref="shang" v-if="'DJI'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="IXIC">
                    <span slot="label">{{ixic}}
                        <el-tooltip placement="bottom">
                            <div slot="content">纳斯达克综合指数是以在美国NASDAQ市场上市的、所有本国和外国的上市公司的普通股为基础进行市值加权计算得到的。指数的证券类型除了普通股，<br/>还包含了美国存托凭证、不动产投资信托等其他证券。科技行业在该指数中的权重最大。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="ixic" ref="shang" v-if="'IXIC'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="SPX">
                    <span slot="label">{{gspc}}
                        <el-tooltip  placement="bottom">
                            <div slot="content">标普500指数以在美国上市的500家大市值公司为样本股，采用自由流通市值加权法计算样本股在指数中的权重。<br/>该指数被广泛地认为是最能反映美国大市值股票表现的指数，因此有许多基金跟踪标普500的表现。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="gspc" ref="shang" v-if="'SPX'==index_code"/>
                </el-tab-pane>
            </el-tabs>
            <span style="position:absolute;left:73%;height:50px;line-height:50px;">预测方法： 
                <el-radio-group v-model="method" @change="radioHangeHandler">
                    <el-radio label="garch_skewt">GARCH法</el-radio>
                    <el-radio label="ewma_hs">EWMA法</el-radio>
                </el-radio-group>                    
            </span>
        </div>
    </div>
</template>

<script>
import Shang from './shang'

export default {
    name: 'america',
    data () {
        return {
            index_code: 'DJI',
            method: 'garch_skewt',
            length: '1y',
            down_color: '#E64545',
            up_color: '#67B35A',
            dji:'道琼斯',
            ixic:'纳斯达克',
            gspc:'标普500',
            isHushenFont: false                     
        }
    },
    components:{
        Shang
    },  
    mounted(){
        this.$nextTick(() => {
            //this.initEcharts()
        })
    },        
    methods: { 
        changeData:function(){
            let params = {
                "index_code": this.index_code,
                "length": this.length,
                "method": this.method,
                "down_color": this.down_color,
                "up_color":this.up_color
            };     
            this.$refs.varEsEcharts.changeData(params) ;
            this.$refs.realizedVolEcharts.changeData(params) ;
        },
        initEcharts: function () {
            //调用子组件的函数，并把这个状态传过去。
            //this.changeData()
        },             
        radioHangeHandler: function (value) {
            this.method = value
            //调用子组件的函数，并把这个状态传过去。
            this.$refs.shang.changeData()
        }, 
        tabClickHandle: function (tab) {
            this.index_code = tab.name
            //调用子组件的函数，并把这个状态传过去。
            //this.changeData() 
        },
    }
}
</script>
<style lang='less'>
.el-tabs__nav-wrap::after {
    content: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    z-index: 1;
}
.el-tabs__item.is-active, .el-tabs__item:hover{
    color: #2E5082;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #5F80A9;
    background: #5F80A9;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #5F80A9;
}
.el-tabs__active-bar {
    background-color: #2E5082;
}
</style>
<style lang='less' scoped>
.tab{
    width:116.5%;
    height:50px;
    // padding:0 16px;
    background:#F7F7F7;
    position:relative;
    border-top:solid 1px #ddd;
}
</style>